import React, { useState, useEffect, useRef } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import './styles.css';
import { useQuery } from "react-query";

const tabName = ["OSD1", "KIN1", "OSG1", "OKZ1", "OWK1", "OJM1", "OGS1"];

function FunnelSettlements() {
    const referenceValue = useRef();
    const [userData, setUserData] = useState({});
    const [index, setIndex] = useState(0);
    const [initialValue, setInitialValue] = useState(false);

    const getInitialData = async () => {
        const response = await fetch(`https://api.ziemiainwestycje.pl/funnel/active-colony-list/`);
        const jsonData = await response.json();
        setUserData(jsonData);
        console.info('initial data has loaded');
    };

    const { data: funnel, isLoading } = useQuery(
        ["funnel", { index }],
        () => fetch(`https://api.ziemiainwestycje.pl/funnel/funnel-data/?colony=${initialValue ? userData.results[index].colony_code : tabName[index]}`).then((response) => response.json()),
    );

    useEffect(() => {
        getInitialData();
        setInitialValue(true);
    }, []);

    useEffect(() => {
        const intervalCall = setInterval(() => {
            setIndex(index !== userData.count - 1 ? index + 1 : 0);
        }, 60000);
        return () => {
            clearInterval(intervalCall);
        };
    }, [index, userData]);

    return isLoading ? (
        <div className="container boxContainer" id="funnelId">Ładowanie...</div>
    ) : (
        <div className="container boxContainer" id="funnelId">
            <button hidden type="button" onClick={() => setIndex(index !== 0 ? index - 1 : userData.count - 1)} style={{ color: 'black', border: 'none', marginRight: '5px' }}>
                poprzednia osada
            </button>
            <button hidden type="button" onClick={() => setIndex(index !== userData.count - 1 ? index + 1 : 0)} style={{ color: 'black', border: 'none', marginRight: '5px' }}>
                nastepna osada
            </button>
            <input hidden disabled ref={referenceValue} value={initialValue ? userData.results[index].colony_code : tabName[index]} placeholder={JSON.stringify(userData.results[index].colony_code)} style={{ color: 'black', border: 'none', marginRight: '5px' }}></input>
            <div className="col">
                <div className="row">
                    <div className="col-12">
                        <div className="row">
                            <div className="col-2">
                            </div>
                            <div className="col-10" style={{ marginBottom: "5vh", marginTop: "2vh", display: 'flex', justifyContent: "center" }}>
                                <h1>{userData.results[index].name}</h1>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-11" style={{ backgroundColor: "rgba(246,244,229, 0.1)" }}>
                        <div className="row">
                            <div className="col-2 p-box left">
                                {parseInt(funnel.results[0].p1).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")}
                            </div>
                            <div className="col-10 boxFunnel p1-box">
                                P1 - ZASIĘG
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-2 p-box left">
                                {parseInt(funnel.results[0].p2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")}
                            </div>
                            <div className="col-9 boxFunnel p2-box">
                                P2 - WEJŚCIA
                                <div className="percentageBox">{isFinite(funnel.results[0].p2 / funnel.results[0].p1) ? isNaN(funnel.results[0].p2 / funnel.results[0].p1) ? '0' : ((funnel.results[0].p2 / funnel.results[0].p1) * 100).toFixed(2) + '%' : '-'}</div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-2 p-box left">
                                {parseInt(funnel.results[0].p3).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")}
                            </div>
                            <div className="col-8 boxFunnel p3-box">
                                P3 - INTERAKCJA
                                <div className="percentageBox">{isFinite(funnel.results[0].p3 / funnel.results[0].p2) ? isNaN(funnel.results[0].p3 / funnel.results[0].p2) ? '0' : ((funnel.results[0].p3 / funnel.results[0].p2) * 100).toFixed(2) + '%' : '-'}</div>
                            </div>
                        </div>
                    </div>
                    <div className="col-1 right" style={{ display: "flex", justifyContent: "center", color: "white", backgroundColor: "rgba(246,244,229,0.1)" }}>MARKETING</div>
                </div>

                <div className="row" >
                    <div className="col-11" style={{ backgroundColor: "rgba(243,235,228,0.15)" }}>
                        <div className="row">
                            <div className="col-2 p-box left">
                                {parseInt(funnel.results[0].p4).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")}
                            </div>
                            <div className="col-7 boxFunnel p4-box">
                                P4 - KONTAKT Z POTENCJALNYM KLIENTEM
                                <div className="percentageBox">{isFinite(funnel.results[0].p4 / funnel.results[0].p3) ? isNaN(funnel.results[0].p4 / funnel.results[0].p3) ? '0' : ((funnel.results[0].p4 / funnel.results[0].p3) * 100).toFixed(2) + '%' : '-'}</div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-2 p-box left">
                                {funnel.results[0].p5}
                            </div>
                            <div className="col-6 boxFunnel p5-box">
                                P5 - UMÓWIONE SPOTKANIA
                                <div className="percentageBox">{isFinite(funnel.results[0].p5 / funnel.results[0].p4) ? isNaN(funnel.results[0].p5 / funnel.results[0].p4) ? '0' : ((funnel.results[0].p5 / funnel.results[0].p4) * 100).toFixed(2) + '%' : '-'}</div>
                            </div>
                        </div>
                    </div>
                    <div className="col-1 right" style={{ display: "flex", justifyContent: "center", color: "white", backgroundColor: "rgba(243,235,228,0.15)" }}>DSZ</div>
                </div>

                <div className="row" >
                    <div className="col-11" style={{ backgroundColor: "rgba(246,244,229,0.2)" }}>
                        <div className="row">
                            <div className="col-2 p-box left">
                                {funnel.results[0].p6}
                            </div>
                            <div className="col-5 boxFunnel p6-box">
                                P6 - ZREALIZOWANE SPOTKANIA
                                <div className="percentageBox">{isFinite(funnel.results[0].p6 / funnel.results[0].p5) ? isNaN(funnel.results[0].p6 / funnel.results[0].p5) ? '0' : ((funnel.results[0].p6 / funnel.results[0].p5) * 100).toFixed(2) + '%' : '-'}</div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-2 p-box left">
                                {funnel.results[0].p7}
                            </div>
                            <div className="col-4 boxFunnel p7-box">
                                P7 - UMOWY REZERWACYJNE
                                <div className="percentageBox">{isFinite(funnel.results[0].p7 / funnel.results[0].p6) ? isNaN(funnel.results[0].p7 / funnel.results[0].p6) ? '0' : ((funnel.results[0].p7 / funnel.results[0].p6) * 100).toFixed(2) + '%' : '-'}</div>
                            </div>
                        </div>
                    </div>
                    <div className="col-1 right" style={{ display: "flex", justifyContent: "center", color: "white", backgroundColor: "rgba(246,244,229,0.2)" }}>DST</div>
                </div>

                <div className="row" >
                    <div className="col-11" style={{ backgroundColor: "rgba(243,235,228, 0.3)" }}>
                        <div className="row">
                            <div className="col-2 p-box left">
                                {funnel.results[0].p8}
                            </div>
                            <div className="col-3 boxFunnel p8-box">
                                P8 - AKTY NOTARIALNE
                                <div className="percentageBox">{isFinite(funnel.results[0].p8 / funnel.results[0].p7) ? isNaN(funnel.results[0].p8 / funnel.results[0].p7) ? '0' : ((funnel.results[0].p8 / funnel.results[0].p7) * 100).toFixed(2) + '%' : '-'}</div>
                            </div>
                        </div>
                    </div>
                    <div className="col-1 right" style={{ display: "flex", justifyContent: "center", color: "white", backgroundColor: "rgba(243,235,228,0.3)" }}>DU</div>
                </div>

                {/* small boxes section */}
                <div className="row" style={{ marginTop: "8vh" }}>
                    <div className="col-5" style={{ marginLeft: "230px" }}>
                        <div className="row" style={{ marginRight: "-50px" }}>
                            <div className="col-2 p-box left">
                            </div>
                            <div className="col-5 boxFunnel p4-box-small">
                                P4 - KONTAKT Z POTENCJALNYM KLIENTEM
                            </div>
                        </div>
                        <div className="row" style={{ marginRight: "-50px" }}>
                            <div className="col-2 p-box" left>
                            </div>
                            <div className="col-5 boxFunnel p8-box-small">
                                P8 - AKTY NOTARIALNE
                                <div className="percentageBox">{isFinite(funnel.results[0].p8 / funnel.results[0].p4) ? isNaN(funnel.results[0].p8 / funnel.results[0].p4) ? '0' : ((funnel.results[0].p8 / funnel.results[0].p4) * 100).toFixed(2) + '%' : '-'}</div>
                            </div>
                        </div>
                    </div>
                    <div className="col-5">
                        <div className="row" style={{ marginRight: "-45px" }}>
                            <div className="col-1 p-box">
                            </div>
                            <div className="col-5 boxFunnel p6-box-small">
                                P6 - ZREALIZOWANE SPOTKANIA
                            </div>
                        </div>
                        <div className="row" style={{ marginRight: "-45px" }}>
                            <div className="col-1 p-box">
                            </div>
                            <div className="col-5 boxFunnel p8-box-small">
                                P8 - AKTY NOTARIALNE
                                <div className="percentageBox">{isFinite(funnel.results[0].p8 / funnel.results[0].p6) ? isNaN(funnel.results[0].p8 / funnel.results[0].p6) ? '0' : ((funnel.results[0].p8 / funnel.results[0].p6) * 100).toFixed(2) + '%' : '-'}</div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}
export default FunnelSettlements;
