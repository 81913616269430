import React from 'react';
import './meetings.scss';
import logoDark from '../../assets/logoDark.svg';
// small icon
import iconTimer from '../../assets/reports/iconTimer.png';
import iconPlus from '../../assets/reports/iconPlus.png';
import iconMinus from '../../assets/reports/iconMinus.png';
// icon Box
import iconBoxPhone from '../../assets/reports/iconBoxPhone.png';
import iconBoxPeople from '../../assets/reports/iconBoxPeople.png';
import iconInfinity from '../../assets/reports/iconInfinity.png';
import iconRightArrow from '../../assets/reports/iconRightArrow.png';
// hook
import useWindowDimensions from '../../hook/useWindowDimensions.js';

function Meetings() {
    const { height, width } = useWindowDimensions();

    return (
        <div className="meetingsContainer">

            {/* SECTION HEADER */}
            <header id="headerMeetingsContact">
                <img src={logoDark} width="162px" alt='Ziemia inwestycje' />
                <h1 className="titleMeetings">Spotkania</h1>
                <div className="switchColonyMeetings">
                    <div>5 / 8</div>
                    <img src={iconTimer} className='imgSizeM' alt='timer' />
                </div>
            </header>
            <span hidden>width: {width} ~ height: {height}</span>

            {/* SECTION BOXES */}
            <section className="wrapperMeetings" style={(height == '1080') && (width == '1920') ? { zoom: '135%' } : undefined}>

                {/* Telefony łącznie */}
                <div className="box1meetings tile tile2x2">
                    <div className='boxTitle'>
                        <img src={iconBoxPhone} className='imgSizeXl' alt='Phone icon' />
                        <div>Telefony łącznie</div>
                    </div>
                    <div className='boxValue'>
                        <div>
                            <p>300 <span>szt.</span></p>
                        </div>
                    </div>
                </div>

                {/* Umówione spotkania */}
                <div className="box2meetings tile tile1x2">
                    <div className='boxTitle'>
                        <img src={iconBoxPeople} className='imgSizeL' alt='People icon' />
                        <div>Umówione spotkania</div>
                    </div>
                    <div className='boxValue'>
                        <div>124 <span>szt.</span></div>
                    </div>
                </div>

                {/* img box right */}
                <div className="box3meetings tile tile1x3img"></div>

                {/* Podpisane umowy rezerwacyjne */}
                <div className="box4meetings tile tile1x2">
                    <div className='boxTitle'>
                        <img src={iconInfinity} className='imgSizeL' alt='Infinity icon' />
                        <div>Podpisane umowy rezerwacyjne</div>
                    </div>
                    <div className='boxValue'>
                        <div>45 <span>szt.</span></div>
                    </div>
                </div>

                {/* Wysłane oferty */}
                <div className="box5meetings tile tile2x2">
                    <div className='boxTitle' style={{ alignSelf: 'start' }}>
                        <img src={iconRightArrow} className='imgSizeXl' alt='Arrow icon' />
                        <div>Wysłane oferty</div>
                    </div>
                    <div className='boxValue'>
                        <div>
                            <p>244 <span>szt.</span></p>
                        </div>
                    </div>
                </div>

                {/* Potwierdzone spotkania */}
                <div className="box6meetings tile tile1x1">
                    <div className='boxTitle'>
                        <div>Potwierdzone spotkania</div>
                    </div>
                    <div className='boxValue'>
                        <div>123 <span>szt.</span></div>
                        <div>
                            <img src={iconMinus} alt='Minus icon' />
                        </div>
                    </div>
                </div>

                {/* img box left */}
                <div className="box7meetings tile tile1x2"></div>

                {/* Odbyte spotkania */}
                <div className="box8meetings tile tile1x2">
                    <div className='boxTitle'>
                        <img src={iconBoxPeople} className='imgSizeL' alt='People icon' />
                        <div>Odbyte spotkania</div>
                    </div>
                    <div className='boxValue'>
                        <div>24 <span>szt.</span></div>
                        <div>
                            <p>+8</p>
                            <img src={iconPlus} alt='Plus icon' />
                        </div>
                    </div>
                </div>

                {/* Leady kwalifikowane */}
                <div className="box9meetings tile tile1x1">
                    <div className='boxTitle'>
                        <p>Leady kwalifikowane</p>
                    </div>
                    <div className='boxValue'>
                        <div>44 444 <span >szt.</span></div>
                    </div>
                </div>

            </section >
        </div >
    )
}
export default Meetings;