import React from 'react';
import './leads.scss';
import logoDark from '../../assets/logoDark.svg';
// small icon
import iconTimer from '../../assets/reports/iconTimer.png';
import iconPlus from '../../assets/reports/iconPlus.png';
import iconMinus from '../../assets/reports/iconMinus.png';
// icon Box
import iconInfinity from '../../assets/reports/iconInfinity.png';
import iconBoxChecked from '../../assets/reports/iconBoxChecked.png'
import iconChart from '../../assets/reports/iconChart.png';
// hook
import useWindowDimensions from '../../hook/useWindowDimensions.js';

function Leads() {
    const { height, width } = useWindowDimensions();

    return (
        <div className="leadsContainer">
            {/* SECTION HEADER */}
            <header id="headerLeads">
                <img src={logoDark} width="162px" alt='Ziemia inwestycje' />
                <h1 className="titleLeads">Leady</h1>
                <div className="switchColonyLeads">
                    <div>1 / 8</div>
                    <img src={iconTimer} className='imgSizeM' alt='timer' />
                </div>
            </header>
            <span hidden>width: {width} ~ height: {height}</span>

            {/* SECTION BOXES */}
            <section className="wrapperLeads" style={(height == '1080') && (width == '1920') ? { zoom: '135%' } : undefined}>
                {/* Leady kwalifikowane - 2x2 */}
                <div className="box1 tile tile2x2">
                    <div className='boxTitle'>
                        <img src={iconBoxChecked} className='imgSizeXl' alt='Checked icon' />
                        <p>Leady kwalifikowane</p>
                    </div>

                    <div className='boxValue'>
                        <div>
                            <p >25 657 <span >szt.</span></p>
                            <div className='boxSmallValue' >
                                <p >+123</p>
                                <img src={iconPlus} alt='Plus icon' />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="smallBox1 blurEffectOne"></div>

                {/* Leady kwalifikowane */}
                <div className="box2 tile tile1x2">
                    <div className='boxTitle'>
                        <img src={iconBoxChecked} className='imgSizeL' alt='Checked icon' />
                        <div>Leady kwalifikowane</div>
                    </div>
                    <div className='boxValue'>
                        <div>12,4%</div>
                        <div className='boxSmallValue' >
                            <p>+0,1</p>
                            <img src={iconPlus} alt='Plus icon' />
                        </div>
                    </div>
                </div>
                <div className="smallBox2 blurEffectOne"></div>

                {/* img box right */}
                <div className="box3 tile tile1x3img"></div>

                {/* Nowe leady */}
                <div className="box4 tile tile1x2">
                    <div className='boxTitle'>
                        <img src={iconInfinity} className='imgSizeL' alt='Infinity icon' />
                        <div>Nowe leady</div>
                    </div>
                    <div className='boxValue'>
                        <div>444 <span>szt.</span></div>
                        <div>
                            <p>+288</p>
                            <img src={iconPlus} alt='Plus icon' />
                        </div>
                    </div>
                </div>
                <div className="smallBox4 blurEffectTwo"></div>

                {/* CAC PPC */}
                <div className="box5 tile tile2x2">
                    <div className='boxTitle'>
                        <img src={iconChart} className='imgSizeXl' alt='Chart icon' />
                        <div>CAC PPC</div>
                    </div>
                    <div className='boxValue'>
                        <div>
                            <p>32 847 <span>zł.</span></p>
                            <div>
                                <p>-1 234</p>
                                <img src={iconMinus} alt='Minus icon' />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="smallBox5 blurEffectThree"></div>

                {/* Koszt kampanii */}
                <div className="box6 tile tile1x1">
                    <div className='boxTitle'>
                        <p>Koszt kampanii</p>
                    </div>
                    <div className='boxValue'>
                        <div>567,8k</div>
                    </div>
                </div>
                <div className="smallBox6 blurEffectTwo"></div>

                {/* img box left */}
                <div className="box7 tile tile1x2img"></div>

                {/* Wszystkie leady */}
                <div className="box8 tile tile1x2">
                    <div className='boxTitle'>
                        <img src={iconInfinity} className='imgSizeL' alt='Infinity icon' />
                        <div>Wszystkie leady</div>
                    </div>
                    <div className='boxValue'>
                        <div>12 876 <span>szt.</span></div>
                    </div>
                </div>
                <div className="smallBox8 blurEffectOne"></div>

                {/* Współczynnik CAC */}
                <div className="box9 tile tile1x1">
                    <div className='boxTitle'>
                        <p>Współczynnik CAC</p>
                    </div>
                    <div className='boxValue'>
                        <div>28%</div>
                        <div>
                            <img src={iconMinus} alt='Minus icon' />
                        </div>
                    </div>
                </div>
                <div className="smallBox9 blurEffectTwo"></div>
            </section >
        </div >
    )
}
export default Leads;

