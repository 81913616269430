import React, { useEffect, useState, Suspense } from 'react';

const Leads = React.lazy(() => import('../Leads'));
const TelephoneContact = React.lazy(() => import('../TelephoneContact'));
const Sale = React.lazy(() => import('../Sale'));
const Meetings = React.lazy(() => import('../Meetings'));
const NotarialAct = React.lazy(() => import('../NotarialAct'));
const Resources = React.lazy(() => import('../Resources'));

const componentsList = [<Sale />, <TelephoneContact />, <Leads />, <Meetings />, <NotarialAct />, <Resources />];

function Marketing() {
    const [index, setIndex] = useState(0);
    const [refreshTime, setRefreshTime] = useState(60000);

    useEffect(() => {
        const interval = setInterval(() => {
            setIndex(index !== componentsList.length - 1 ? index + 1 : 0);
            (index == 1) ? setRefreshTime(420000) : setRefreshTime(60000);
        }, refreshTime);
        return () => clearInterval(interval);
    }, [index, refreshTime]);

    return (
        <>
            <Suspense fallback={<></>}>
                {componentsList[index]};
            </Suspense >
        </>
    )
}

export default Marketing;