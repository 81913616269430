import React from 'react';
import './resources.scss';
import logoDark from '../../assets/logoDark.svg';
// small icon
import iconTimer from '../../assets/reports/iconTimer.png';
// icon Box
import iconBoxRouter from '../../assets/reports/iconBoxRouter.png';
// hook
import useWindowDimensions from '../../hook/useWindowDimensions.js';

function Resources() {
    const { height, width } = useWindowDimensions();

    return (
        <div className="resourcesContainer">

            {/* SECTION HEADER */}
            <header id="headerResources">
                <img src={logoDark} width="162px" alt='Ziemia inwestycje' />
                <h1 className="titleResources">Zasoby</h1>
                <div className="switchColonyResources">
                    <div>7 / 8</div>
                    <img src={iconTimer} className='imgSizeM' alt='timer' />
                </div>
            </header>
            <span hidden>width: {width} ~ height: {height}</span>

            {/* SECTION BOXES */}
            <section className="wrapperResources" style={(height == '1080') && (width == '1920') ? { zoom: '135%' } : undefined}>

                {/* Wartość sprzedanych działek */}
                <div className="box1resources tile tile2x2long">
                    <div className='resourcesBoxTitle'>
                        <img src={iconBoxRouter} className='imgSizeXl' alt='Router icon' />
                        <p>Wartość sprzedanych działek</p>
                    </div>
                    <div className='resourcesBoxValue'>
                        <div>
                            <p className='resourcesBoxValueSingle'>44 444 444 <span className='resourceBoxValueInit'>zł</span></p>
                        </div>
                    </div>
                </div>

                {/* Wartość niesprzedanych działek */}
                <div className="box2resources tile tile2x2long">
                    <div className='resourcesBoxTitle'>
                        <img src={iconBoxRouter} className='imgSizeXl' alt='Router icon' />
                        <p>Wartość niesprzedanych działek</p>
                    </div>
                    <div className='resourcesBoxValue'>
                        <div>
                            <p className='resourcesBoxValueSingle'>44 444 444 <span className='resourceBoxValueInit'>zł</span></p>
                        </div>
                    </div>
                </div>

                {/* Magazyn działek */}
                <div className="box3resources tile tile1x2long">
                    <div className='resourcesBoxTitle'>
                        <img src={iconBoxRouter} className='imgSizeL' alt='Router icon' />
                        <div>Magazyn działek</div>
                    </div>
                    <div className='resourcesBoxValue' >
                        <div className='resourceBoxValueSingle'>500 <span className='resourceBoxValueInit'>szt</span></div>
                    </div>
                </div>

                {/* Działki do sprzedania */}
                <div className="box4resources tile tile1x2long">
                    <div className='resourcesBoxTitle'>
                        <img src={iconBoxRouter} className='imgSizeL' alt='Router icon' />
                        <div>Działki do sprzedania</div>
                    </div>
                    <div className='resourcesBoxValue' >
                        <div className='resourceBoxValueSingle'>500 <span className='resourceBoxValueInit'>szt</span></div>
                    </div>
                </div >

                {/* img box bottom */}
                < div className="box5resources tile tile1x4long" ></div >
            </section >
        </div >
    )
}
export default Resources;