import React from 'react';
import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";
import Counter from './components/Counter';
import Funnel from './components/Funnel';
import NotFound from './components/NotFound';
import Home from './components/Home';
import FunnelSettlements from './components/FunnelSettlements';
import TelephoneContact from './pages/TelephoneContact';
import Leads from './pages/Leads';
import GlobalTarget from './pages/GlobalTarget';
import GlobalFunnel from './pages/GlobalFunnel';
import Sale from './pages/Sale';
import Meetings from './pages/Meetings';
import NotarialAct from './pages/NotarialAct';
import Resources from './pages/Resources';
import Marketing from './pages/Marketing';
import HrTable from './pages/HrTable';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="*" element={<NotFound />} />
        <Route path="/" element={<Home />} />
        <Route path='licznik' element={<Counter />} />
        <Route path='lejek-all' element={<Funnel />} />
        <Route path='lejek-osad' element={<FunnelSettlements />} />
        <Route path='/raporty/kontakt-telefoniczny' element={<TelephoneContact />} />
        <Route path='/raporty/leady' element={<Leads />} />
        <Route path='/raporty/target-globalny' element={<GlobalTarget />} />
        <Route path='/raporty/lejek-marketingowy' element={<GlobalFunnel />} />
        <Route path='/raporty/sprzedaz' element={<Sale />} />
        <Route path='/raporty/spotkania' element={<Meetings />} />
        <Route path='/raporty/akty-notarialne' element={<NotarialAct />} />
        <Route path='/raporty/zasoby' element={<Resources />} />
        <Route path='/raporty/marketing' element={<Marketing />} />
        <Route path='/tablica-hr' element={<HrTable />} />
      </Routes>
    </BrowserRouter>
  );
}
export default App;