import React from 'react';
import './notarial-act.scss';
import logoDark from '../../assets/logoDark.svg';
// small icon
import iconTimer from '../../assets/reports/iconTimer.png';
import iconPlus from '../../assets/reports/iconPlus.png';
import iconMinus from '../../assets/reports/iconMinus.png';
import lineBox from '../../assets/reports/lineBox.png';
// icon Box
import iconBoxPeople from '../../assets/reports/iconBoxPeople.png';
// hook
import useWindowDimensions from '../../hook/useWindowDimensions.js';

function NotarialAct() {
    const { height, width } = useWindowDimensions();

    return (
        <div className="notarialActContainer">

            {/* SECTION HEADER */}
            <header id="headerNotarialAct">
                <img src={logoDark} width="162px" alt='Ziemia inwestycje' />
                <h1 className="titleNotarialAct">Akty notarialne</h1>
                <div className="switchColonyNotarialAct">
                    <div>6 / 8</div>
                    <img src={iconTimer} className='imgSizeM' alt='timer' />
                </div>
            </header>
            <span hidden>width: {width} ~ height: {height}</span>

            {/* SECTION BOXES */}
            <section className="wrapperNotarialAct" style={(height == '1080') && (width == '1920') ? { zoom: '145%' } : undefined}>

                {/* Akty notarialne w szt. */}
                <div className="box1notarialAct tile tile1x2">
                    <div className="notarialActBoxTitle">
                        <div>Akty notarialne w szt.</div>
                        <img src={lineBox} alt='Line' />
                        <div>Akty notarialne w zł</div>
                    </div>
                    <div className="notarialActBoxTitle">
                        <div>444 <span className="notarialActBoxValue">szt.</span></div>
                        <img src={lineBox} alt='Line' />
                        <div>44 444 444 <span className="notarialActBoxValue">szt.</span></div>
                    </div>
                </div>

                {/* img box left 1 */}
                <div className="box2notarialAct tile tile1x1img"></div>

                {/* Akty not. umówione */}
                <div className="box3notarialAct tile tile1x2">
                    <div className="notarialActBoxTitle">
                        <div>Akty not. <strong>umówione</strong> w szt.</div>
                        <img src={lineBox} alt='Line' />
                        <div>Akty not. <strong>umówione</strong> w zł</div>
                    </div>
                    <div className="notarialActBoxTitle" >
                        <div>987 <span className="notarialActBoxValue">szt.</span></div>
                        <img src={lineBox} alt='Line' />
                        <div>9 876 543 <span className="notarialActBoxValue">szt.</span></div>
                    </div>
                </div>

                {/* img box right 1 */}
                <div className="box4notarialAct tile tile1x1img"></div>

                {/* img box left 2 */}
                <div className="box5notarialAct tile tile1x1img"></div>

                {/* Akty not. do umówienia */}
                <div className="box6notarialAct tile tile1x2">
                    <div className="notarialActBoxTitle">
                        <div>Akty not. <strong>do umówienia</strong> w szt.</div>
                        <img src={lineBox} alt='Line' />
                        <div>Akty not. <strong>do umówienia</strong> w zł.</div>
                    </div>
                    <div className="notarialActBoxTitle" >
                        <div>123 <span className="notarialActBoxValue">szt.</span></div>
                        <img src={lineBox} alt='Line' />
                        <div>12 345 678 <span className="notarialActBoxValue">szt.</span></div>
                    </div>
                </div>

                {/* img box right 2 */}
                <div className="box7notarialAct tile tile1x1img"></div>

                {/* Odbyte spotkania */}
                <div className="box8notarialAct tile tile1x2">
                    <div className="notarialActMeetingsTitle" >
                        <img src={iconBoxPeople} className='imgSizeL' alt='People icon' />
                        <div>Odbyte spotkania</div>
                    </div>
                    <div className="notarialActMeetingsValue">
                        <div>24 <span className="notarialActBoxValue">szt.</span></div>
                        <div className="notarialActMeetingsValueStatus">
                            <p>+8</p>
                            <img src={iconPlus} alt='Plus icon' />
                        </div>
                    </div>
                </div>
            </section >
        </div >
    )
}
export default NotarialAct;