import React from "react";
import "./styles.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import FlipNumbers from "react-flip-numbers";              // library for value animation
import alertMusic from '../../assets/drop_coin.mp3';       // path to sound file
import { Howl } from 'howler';                             // sound library

// global variable - ENDPOINT
const API = 'https://api.ziemiainwestycje.pl/counter/counter-data/'; // API 'counter-data'

function Counter() {
    const [apiValue, setApiValue] = React.useState(false);           // state with inital value
    const errorDataShort = '-';                                      // error handling short version
    const unit = 'zł';                                               // unit (currency)

    // global API
    const [data, setData] = React.useState([]);                                                                                  // state container
    const dataValueShort = apiValue ? parseInt(data.current_sales_volume).toFixed() : '-';                                      // current_sales_volume as integer
    const dataTargetValue = apiValue ? parseInt(data.target_value).toFixed() : errorDataShort;                                   // 'TARGET'
    const dataPercentage = apiValue ? Math.round((parseInt(data.current_sales_volume) / parseInt(data.target_value)) * 100) + '%' : errorDataShort; // % celu
    const dataCurrentInflow = apiValue ? parseInt(data.current_inflow).toFixed() : errorDataShort;                                    // 'Aktualny wpływ'
    const dataSignedContracts = apiValue ? data.signed_contracts : errorDataShort;                                               // 'Podpisane umowy'
    const dataTransferredOwnership = apiValue ? data.transferred_ownership : errorDataShort;                                     // 'Przeniesione umowy'
    const dataRemainingSales = apiValue ? parseInt(data.target_value - data.current_sales_volume).toFixed() : errorDataShort; // 'Pozostało'
    const referenceValue = React.useRef();                                                                                       //  Ref to 'current_sales_volume'

    const sound = new Howl({ src: [alertMusic] });                                                                               // function for sound
    const playAlert = () => { sound.play() && console.warn('coin music'); }

    const initialData = () => {
        fetch(`${API}`)
            .then(function (response) {
                return response.json();
            })
            .then(function (myJson) {
                setData(myJson.results[0]);
                setApiValue(true);
                console.info('initial data has loaded: ', data);
            })
    }

    const getData = () => {
        fetch(`${API}`)
            .then(function (response) {
                return response.json();
            })
            .then(function (myJson) {
                if (JSON.stringify(parseInt(myJson.results[0].current_sales_volume).toFixed()) === JSON.stringify(parseInt(referenceValue.current.value).toFixed())) {
                    console.log('false, previous & current value: ', JSON.stringify(parseInt(myJson.results[0].current_sales_volume).toFixed()), JSON.stringify(parseInt(referenceValue.current.value).toFixed()));
                }
                else {
                    console.warn('true, new value: ', JSON.stringify(parseInt(referenceValue.current.value).toFixed()));
                    setData(myJson.results[0]);
                    playAlert();
                }
            });
    }

    React.useEffect(() => {
        initialData();
        const intervalCall = setInterval(() => {
            getData();
            console.log('>> refresh after 5 min');
        }, 300000);
        return () => {
            clearInterval(intervalCall);
        };
    }, []);

    return (
        <div id="backgroundId">
            <div className="d-flex align-items-center justify-content-center text-center">
                <div className="container">

                    <div className="row my-5">
                        <div className="col-sm">
                            <span className="text-center title">AKTUALNA SPRZEDAŻ</span>
                        </div>
                    </div>

                    <div className="row my-5">
                        <div className="col-sm">
                            <div>
                                <div id="number" className="d-flex justify-content-center">
                                    <span className="topValue">
                                        <FlipNumbers
                                            numbers={dataValueShort + unit}
                                            width={165}
                                            height={133}
                                            duration={5}
                                            play
                                        />
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row mb-5" >
                        <div className="col-sm ">
                            <span className="title">Target: {dataTargetValue.replace(/\B(?=(\d{3})+(?!\d))/g, " ")} {unit}</span>
                            <span className="title break">{dataPercentage}</span>
                            <span className="title break">Aktualny wpływ: {dataCurrentInflow.replace(/\B(?=(\d{3})+(?!\d))/g, " ")} {unit}</span>
                        </div>
                        <div className="col-sm ">
                            <span className="title">Podpisane umowy: {dataSignedContracts}</span>
                            <span className="title break" >Przeniesione umowy: {dataTransferredOwnership}</span>
                        </div>
                    </div>

                    <div className="row mb-5" style={{ marginTop: "-10px" }}>
                        <div className="col-sm text-left" style={{ marginLeft: '-280px' }}>
                            <span className="title smaller" style={!apiValue ? { marginLeft: '50px' } : undefined}>Pozostało: {dataRemainingSales.replace(/\B(?=(\d{3})+(?!\d))/g, " ")} {unit}</span>
                        </div>
                        <div className="col-sm text-right"></div>
                    </div>

                    <input className="hidden" ref={referenceValue} value={dataValueShort} placeholder={dataValueShort} disabled></input>
                </div>
            </div>
        </div >
    );
}
export default Counter;
