import React, { useState, useEffect, useRef } from 'react';
import './global-target.scss';
import logoDark from '../../assets/logoDark.svg';
// small icon
import iconTimer from '../../assets/reports/iconTimer.png';
// icon Box
import iconCircle from '../../assets/reports/iconCircle.png';
import iconExit from '../../assets/reports/iconExit.png';
// hook
import useWindowDimensions from '../../hook/useWindowDimensions.js';
// animation library
import FlipNumbers from "react-flip-numbers";              // library for value animation
// sound library
import alertMusic from '../../assets/drop_coin.mp3';       // path to sound file
import { Howl } from 'howler';                             // sound library

const API = 'https://dev-api.ziemiainwestycje.pl/counter/target-data/'; // API 'counter-data'

function GlobalTarget() {
    const { height, width } = useWindowDimensions();
    const [apiValue, setApiValue] = useState(false);           // state with inital value
    const [data, setData] = useState([]);
    const referenceValue = useRef();
    // static value
    const unitPln = 'zł';
    const unitSzt = 'szt';
    const realizationTargetValue = apiValue ? parseInt(data.net_sales_value).toFixed() : '-'; // Realizacja targetu
    const salesValue = apiValue ? parseInt(data.target_realization).toFixed() : '-'; // Aktualny wpływ
    const targetValue = apiValue ? parseInt(45000000).toFixed() : '-'; // Target
    const targetValueWeekly = apiValue ? (targetValue / 52).toFixed(0) : '-'; // Target tygodniowy
    const soldPlotsValue = apiValue ? parseInt(data.sold_plots_value).toFixed() : '-'; // Wartość sprzedanych działek
    const unsoldPloteValue = apiValue ? parseInt(data.unsold_plots_value).toFixed() : '-'; // Wartość sprzedanych działek
    const targetAchievement = apiValue ? Math.round((parseInt(realizationTargetValue) / parseInt(targetValue)) * 100) + '%' : '-'; // Realizacja targetu
    const numberOfPrepayments = apiValue ? parseInt(data.number_of_prepayments + 11).toFixed() : '-'; // Zadatki
    const numberOfNotarialActs = apiValue ? parseInt(data.number_of_notarial_acts + 41).toFixed() : '-'; // Zadatki
    const targetAchievementWeekly = apiValue ? Math.round((parseInt(realizationTargetValue / 52) / parseInt(targetValue / 52)) * 100) + '%' : '-'; // Realizacja targetu tygodniowego

    const sound = new Howl({ src: [alertMusic] });                                                                               // function for sound
    const playAlert = () => { sound.play() && console.warn('coin music'); }

    const initialData = () => {
        fetch(`${API}`)
            .then(function (response) {
                return response.json();
            })
            .then(function (myJson) {
                setData(myJson.results[0]);
                setApiValue(true);
                console.info('initial data has loaded: ', data);
            })
    }

    const getData = () => {
        fetch(`${API}`)
            .then(function (response) {
                return response.json();
            })
            .then(function (myJson) {
                if (JSON.stringify(parseInt(myJson.results[0].target_realization).toFixed()) === JSON.stringify(parseInt(referenceValue.current.value).toFixed())) {
                    console.log('false, previous & current value: ', JSON.stringify(parseInt(myJson.results[0].target_realization).toFixed()), JSON.stringify(parseInt(referenceValue.current.value).toFixed()));
                }
                else {
                    console.warn('true, new value: ', JSON.stringify(parseInt(referenceValue.current.value).toFixed()));
                    setData(myJson.results[0]);
                    playAlert();
                }
            });
    }

    useEffect(() => {
        initialData();
        const intervalCall = setInterval(() => {
            getData();
            console.log('>> refresh after 5 min');
        }, 300000);
        return () => {
            clearInterval(intervalCall);
        };
    }, []);

    return (
        <div className="targetGlobalContainer">
            {/* SECTION HEADER */}
            <header id="headerGlobalTarget">
                <img src={logoDark} width="162px" alt='Ziemia inwestycje' />
                <h1 className="titleGlobalTarget">Target globalny</h1>
                <div className="switchColonyGlobalTarget">
                    <div hidden>1 / 8</div>
                    <img hidden src={iconTimer} className='imgSizeM' alt='timer' />
                </div>
            </header>
            <span hidden>width: {width} ~ height: {height}</span>

            {/* SECTION BOXES */}
            <section className="wrapperGlobalTarget" style={(height == '1080') && (width == '1920') ? { zoom: '135%' } : undefined}>
                {/* Realizacja targetu */}
                <div className="box1 tile tile1x5">
                    <div className='boxTitle'>
                        <img src={iconCircle} className='imgSizeXl' alt='Circle icon' />
                        <p>Realizacja <br /> targetu</p>
                    </div>
                    <div className='boxValue'>
                        <div className='flipContainer'>
                            <FlipNumbers
                                numbers={apiValue ? realizationTargetValue + unitPln : '-'}
                                width={50}
                                height={60}
                                duration={5}
                                play
                            />
                            {/* <div>{realizationTargetValue.replace(/\B(?=(\d{3})+(?!\d))/g, " ")} <span>zł</span></div> */}
                        </div>
                    </div>
                </div>
                <div className="smallBox1"></div>

                {/* Aktualny wpływ */}
                <div className="box2 tile tile1x2long">
                    <div className='boxTitle'>
                        <img src={iconExit} className='imgSizeL' alt='Exit icon' />
                        <div>Aktualny wpływ</div>
                    </div>
                    <div className='boxValue'>
                        <div>{salesValue.split(/(?=(?:\d{3})+(?:\.|$))/g).join(",").slice(0, 5)} mln <span>zł</span></div>
                        {/* <div>{salesValue.split(/(?=(?:\d{3})+(?:\.|$))/g).join(",")} mln <span>zł</span></div> */}
                        {/* <div>{salesValue.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")} mln <span>zł</span></div> */}
                    </div>
                </div>
                <div className="smallBox2"></div>

                {/* Target */}
                <div className="box3 tile tile1x2long">
                    <div className='boxTitle'>
                        <img src={iconCircle} className='imgSizeL' alt='Circle icon' />
                        <div>Target</div>
                    </div>
                    <div className='boxValue'>
                        <div>{targetValue.replace(/\B(?=(\d{3})+(?!\d))/g, " ")} <span>zł</span></div>
                    </div>
                </div>
                <div className="smallBox3"></div>

                {/* Target tygodniowy */}
                <div className="box4 tile tile1x2long">
                    <div className='boxTitle'>
                        <img src={iconCircle} className='imgSizeL' alt='Circle icon' />
                        <div>Target tygodniowy</div>
                    </div>
                    <div className='boxValue'>
                        <div>{targetValueWeekly.replace(/\B(?=(\d{3})+(?!\d))/g, " ")} <span>zł</span></div>
                    </div>
                </div>
                <div className="smallBox4"></div>

                {/* Wartość sprzedanych działek */}
                <div className="box5 tile tile1x1long">
                    <div className='boxTitle'>
                        <p>Wartość sprzedanych działek</p>
                    </div>
                    <div className='boxValue'>
                        <div>{soldPlotsValue.split(/(?=(?:\d{3})+(?:\.|$))/g).join(",").slice(0, 6)} mln <span>zł</span></div>
                    </div>
                </div>
                <div className="smallBox5"></div>

                {/* Wartość działek dostępnych w sprzedaży */}
                <div className="box6 tile tile1x1long">
                    <div className='boxTitle'>
                        <p>Wartość działek dostępnych w sprzedaży</p>
                    </div>
                    <div className='boxValue'>
                        <div >{unsoldPloteValue.split(/(?=(?:\d{3})+(?:\.|$))/g).join(",").slice(0, 6)} mln <span>zł</span></div>
                    </div>
                </div>
                <div className="smallBox6"></div>

                {/* Realizacja targetu */}
                <div className="box7 tile tile1x2long">
                    <div className='boxTitle'>
                        <img src={iconCircle} className='imgSizeL' alt='Circle icon' />
                        <div>Realizacja targetu</div>
                    </div>
                    <div className='boxValue'>
                        <div>{targetAchievement}</div>
                    </div>
                </div>
                <div className="smallBox7"></div>

                {/* Realizacja targetu tygodniowego */}
                <div className="box8 tile tile1x2long">
                    <div className='boxTitle inactive'>
                        <img src={iconCircle} className='imgSizeL' alt='Circle icon' />
                        <div>Realizacja targetu tygodniowego</div>
                    </div>
                    <div className='boxValue inactive'>
                        <div>{targetAchievementWeekly}</div>
                    </div>
                </div>
                <div className="smallBox8"></div>

                {/* Zadatki */}
                <div className="box9 tile tile1x1long">
                    <div className='boxTitle'>
                        <p>Zadatki</p>
                    </div>
                    <div className='boxValue'>
                        <div>{numberOfPrepayments} <span>{unitSzt}</span></div>
                    </div>
                </div>
                <div className="smallBox9"></div>

                {/* Podpisane akty notarialne */}
                <div className="box10 tile tile1x1long">
                    <div className='boxTitle'>
                        <p>Podpisane akty notarialne</p>
                    </div>
                    <div className='boxValue'>
                        <div>{numberOfNotarialActs} <span>{unitSzt}</span></div>
                    </div>
                </div>
                <div className="smallBox10"></div>
            </section >
        </div >
    )
}
export default GlobalTarget;
