import React, { useState, useEffect, useRef } from 'react';
import './global-funnel.scss';
import logoDark from '../../assets/logoDark.svg';
// small icon
import iconTimer from '../../assets/reports/iconTimer.png';
// icon Box
import iconCircleP1 from '../../assets/reports/iconCircleP1.png';
import iconCircleP2 from '../../assets/reports/iconCircleP2.png';
import iconCircleP3 from '../../assets/reports/iconCircleP3.png';
import iconCircleP4 from '../../assets/reports/iconCircleP4.png';
import iconCircleP5 from '../../assets/reports/iconCircleP5.png';
import iconCircleP6 from '../../assets/reports/iconCircleP6.png';
import iconCircleP7 from '../../assets/reports/iconCircleP7.png';
import iconCircleP8 from '../../assets/reports/iconCircleP8.png';
import iconCircleP4Color from '../../assets/reports/iconCircleP4Color.png';
import iconCircleP5Color from '../../assets/reports/iconCircleP5Color.png';
import iconCircleP6Color from '../../assets/reports/iconCircleP6Color.png';
import iconCircleP7Color from '../../assets/reports/iconCircleP7Color.png';
import iconCircleP8Color from '../../assets/reports/iconCircleP8Color.png';
import lineYDashed from '../../assets/reports/lineYDashed.png';
// hook
import useWindowDimensions from '../../hook/useWindowDimensions.js';
// library for state
import { useQuery } from "react-query";

const tabName = ["ODW1", "KIN1", "OSG1", "OKZ1", "OWK1", "OJM1", "OGS1", "OSD1"];

// https://codesandbox.io/s/stoic-flower-upo83?file=/App.tsx:517-529

function GlobalFunnel() {
    const { height, width } = useWindowDimensions();
    const referenceValue = useRef();
    const [userData, setUserData] = useState({});
    const [index, setIndex] = useState(0);
    const [initialValue, setInitialValue] = useState(false);

    const getInitialData = async () => {
        const response = await fetch(`https://dev-api.ziemiainwestycje.pl/funnel/active-colony-list/`);
        const jsonData = await response.json();
        setUserData(jsonData);
        console.info('initial data has loaded');
    };

    const { data: funnel, isLoading } = useQuery(
        ["funnel", { index }],
        () => fetch(`https://dev-api.ziemiainwestycje.pl/funnel/funnel-data/?colony=${initialValue ? userData.results[index].colony_code : tabName[index]}`).then((response) => response.json()),
    );

    useEffect(() => {
        getInitialData();
        setInitialValue(true);
    }, []);

    useEffect(() => {
        const intervalCall = setInterval(() => {
            setIndex(index !== userData.count - 1 ? index + 1 : 0);
        }, 60000);
        return () => {
            clearInterval(intervalCall);
        };
    }, [index, userData]);

    // return (
    return isLoading ? (
        <div className="container boxContainer" id="funnelId">Ładowanie...</div>
    ) : (
        <div className="funnelGlobalContainer">
            <button hidden type="button" onClick={() => setIndex(index !== 0 ? index - 1 : userData.count - 1)} style={{ color: 'black', border: 'none', marginRight: '5px' }}>
                poprzednia osada
            </button>
            <button hidden type="button" onClick={() => setIndex(index !== userData.count - 1 ? index + 1 : 0)} style={{ color: 'black', border: 'none', marginRight: '5px' }}>
                nastepna osada
            </button>
            <input hidden disabled ref={referenceValue} value={initialValue ? userData.results[index].colony_code : tabName[index]} placeholder={JSON.stringify(userData.results[index].colony_code)} style={{ color: 'black', border: 'none', marginRight: '5px' }}></input>

            {/* SECTION HEADER */}
            <header id="headerGlobalFunnel">
                <img src={logoDark} width="162px" alt='Ziemia inwestycje' />
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <h2 style={{ fontSize: '16px', color: 'rgba(255, 255, 255, 0.75)', letterSpacing: '0.12em' }}>{userData.results[index].name.toUpperCase()}</h2>
                    <h1 className="titleGlobalFunnel">Podróż klienta po organizacji</h1>
                </div>
                <div className="switchColonyGlobalFunnel">
                    <div>{index !== userData.count - 1 ? index + 1 : userData.count} / {initialValue ? userData.results.length : tabName.length}</div>
                    <img hidden src={iconTimer} className='imgSizeM' alt='Timer' />
                </div>
            </header>
            <span hidden>width: {width} ~ height: {height}</span>

            {/* SECTION MAIN BOX */}
            <section className="wrapperGlobalFunnel" style={(height == '1080') && (width == '1920') ? { zoom: '135%' } : undefined}>
                a
                {/* boxMain */}
                <div className="boxMain tile">
                    <div className="boxMainData">

                        {/* P1 container */}
                        <div className="p1container pContainer">
                            <div className='pText'>{parseInt(funnel.results[0].p1).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")}</div>
                            <div className='singleFunnel'>
                                <img src={iconCircleP1} className='imgSizeM positionElementsFunnel' alt='Circle icon' />
                                <div className='pColor'>ZASIĘG</div>
                            </div>
                        </div>

                        {/* P2 container */}
                        <div className="p2container pContainer">
                            <div className='pText'>{parseInt(funnel.results[0].p2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")}</div>
                            <div className='pColor singleFunnel'>
                                <img src={iconCircleP2} className='imgSizeM positionElementsFunnel' alt='Circle icon' />
                                <div className='pColor'>WEJŚCIA</div>
                                <div className='p2Percentage pPercentageBox'>{isFinite(funnel.results[0].p2 / funnel.results[0].p1) ? isNaN(funnel.results[0].p2 / funnel.results[0].p1) ? '0' : ((funnel.results[0].p2 / funnel.results[0].p1) * 100).toFixed(2) + '%' : '-'}</div>
                            </div>
                        </div>

                        {/* P3 container */}
                        <div className="p3container pContainer">
                            <div className='pText'>{parseInt(funnel.results[0].p3).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")}</div>
                            <div className='pColor singleFunnel'>
                                <img src={iconCircleP3} className='imgSizeM positionElementsFunnel' alt='Circle icon' />
                                <div className='pColor'>INTERAKCJA</div>
                                <div className='p3Percentage pPercentageBox'>{isFinite(funnel.results[0].p3 / funnel.results[0].p2) ? isNaN(funnel.results[0].p3 / funnel.results[0].p2) ? '0' : ((funnel.results[0].p3 / funnel.results[0].p2) * 100).toFixed(2) + '%' : '-'}</div>
                            </div>
                        </div>

                        {/* P4 container */}
                        <div className="p4container pContainer">
                            <div className='pText'>{parseInt(funnel.results[0].p4).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")}</div>
                            <div className='pColor singleFunnel'>
                                <img src={iconCircleP4} className='imgSizeM positionElementsFunnel' alt='Circle icon' />
                                <div className='pColor'>KONTAKT Z POTENCJALNYM KLIENTEM</div>
                                <div className='p4Percentage pPercentageBox'>{isFinite(funnel.results[0].p4 / funnel.results[0].p3) ? isNaN(funnel.results[0].p4 / funnel.results[0].p3) ? '0' : ((funnel.results[0].p4 / funnel.results[0].p3) * 100).toFixed(2) + '%' : '-'}</div>
                            </div>
                        </div>

                        {/* P5 container */}
                        <div className="p5container pContainer">
                            <div className='pText'>{funnel.results[0].p5}</div>
                            <div className='pColor singleFunnel'>
                                <img src={iconCircleP5} className='imgSizeM positionElementsFunnel' alt='Circle icon' />
                                <div className='pColor'>UMÓWIONE SPOTKANIA</div>
                                <div className='p5Percentage pPercentageBox'>{isFinite(funnel.results[0].p5 / funnel.results[0].p4) ? isNaN(funnel.results[0].p5 / funnel.results[0].p4) ? '0' : ((funnel.results[0].p5 / funnel.results[0].p4) * 100).toFixed(2) + '%' : '-'}</div>
                            </div>
                        </div>

                        {/* P6 container */}
                        <div className="p6container pContainer">
                            <div className='pText'>{funnel.results[0].p6}</div>
                            <div className='pColor'>
                                <img src={iconCircleP6} className='imgSizeM positionElementsFunnel' alt='Circle icon' />
                                <div className='pColor'>ZREALIZOWANE SPOTKANIA</div>
                                <div className='p6Percentage pPercentageBox'>{isFinite(funnel.results[0].p6 / funnel.results[0].p5) ? isNaN(funnel.results[0].p6 / funnel.results[0].p5) ? '0' : ((funnel.results[0].p6 / funnel.results[0].p5) * 100).toFixed(2) + '%' : '-'}</div>
                            </div>
                        </div>

                        {/* P7 container */}
                        <div className="p7container pContainer">
                            <div className='pText'>{funnel.results[0].p7}</div>
                            <div className='pColor singleFunnel' >
                                <img src={iconCircleP7} className='imgSizeM' alt='Circle icon' />
                                <div className='pColor'>UMOWY REZERWACYJNE</div>
                                <div className='p7Percentage pPercentageBox'>{isFinite(funnel.results[0].p7 / funnel.results[0].p6) ? isNaN(funnel.results[0].p7 / funnel.results[0].p6) ? '0' : ((funnel.results[0].p7 / funnel.results[0].p6) * 100).toFixed(2) + '%' : '-'}</div>
                            </div>
                        </div>

                        {/* P8 container */}
                        <div className="p8container pContainer">
                            <div className='pText'>{funnel.results[0].p8}</div>
                            <div className='pColor singleFunnel' >
                                <img src={iconCircleP8} className='imgSizeM positionElementsFunnel' alt='Circle icon' />
                                <div className='pColor'>AKTY NOTARIALNE</div>
                                <div className='p8Percentage pPercentageBox'>{isFinite(funnel.results[0].p8 / funnel.results[0].p7) ? isNaN(funnel.results[0].p8 / funnel.results[0].p7) ? '0' : ((funnel.results[0].p8 / funnel.results[0].p7) * 100).toFixed(2) + '%' : '-'}</div>
                            </div>
                        </div>

                        {/* MARKETING box label */}
                        <div className='funnelMarketingBox funnelRightBoxes'>MARKETING</div>

                        {/* DSZ box label */}
                        <div className='funnelDszBox funnelRightBoxes'>DSZ</div>

                        {/* DST box label */}
                        <div className='funnelDstBox funnelRightBoxes'>DST</div>

                        {/* DU box label */}
                        <div className='funnelDuBox funnelRightBoxes'>DU</div>

                    </div>
                </div>

                {/* Box p4p8 */}
                <div className="box1 tile tile1x3long">
                    <div className='rightBoxContainer'>
                        <div>
                            <img src={iconCircleP4Color} className='imgSizeM' alt='Circle' />
                            <img src={iconCircleP8Color} className='imgSizeM' alt='Circle' />
                        </div>
                        <div>
                            <div className='valueLabel'>Kontakt z klientem</div>
                            <div className='valueLabel'>Akty notarialne</div>
                        </div>
                        <img src={lineYDashed} className='lineYDashedPos' alt='Line' />
                        <div className='percentageContainer' >
                            <p className='percentageValue'>{isFinite(funnel.results[0].p8 / funnel.results[0].p4) ? isNaN(funnel.results[0].p8 / funnel.results[0].p4) ? '0' : ((funnel.results[0].p8 / funnel.results[0].p4) * 100).toFixed(2) + '%' : '-'}</p>
                        </div>
                    </div>
                    <div className='box1ShadowContainer'>
                        <div className='box1Shadow'></div>
                        <div className='box1ShadowMask'></div>
                    </div>
                </div>

                {/* Box p6p8 */}
                <div className="box2 tile tile1x3long">
                    <div className='rightBoxContainer'>
                        <div>
                            <img src={iconCircleP6Color} className='imgSizeM' alt='Circle' />
                            <img src={iconCircleP8Color} className='imgSizeM' alt='Circle' />
                        </div>
                        <div>
                            <div className='valueLabel'>Zrealizowane spotkania</div>
                            <div className='valueLabel'>Akty notarialne</div>
                        </div>
                        <img src={lineYDashed} className='lineYDashedPos' alt='Line' />
                    </div>
                    <div className='percentageContainer' >
                        <p className='percentageValue'>{isFinite(funnel.results[0].p8 / funnel.results[0].p6) ? isNaN(funnel.results[0].p8 / funnel.results[0].p6) ? '0' : ((funnel.results[0].p8 / funnel.results[0].p6) * 100).toFixed(2) + '%' : '-'}</p>
                    </div>
                    {/* <div className='box2ShadowContainer'>
                        <div className='box2Shadow'></div>
                        <div className='box2ShadowMask'></div>
                    </div> */}
                </div>

                {/* Box p5p7 */}
                <div className="box3 tile tile1x3long">
                    <div className='rightBoxContainer'>
                        <div>
                            <img src={iconCircleP5Color} className='imgSizeM' alt='Circle' />
                            <img src={iconCircleP7Color} className='imgSizeM' alt='Circle' />
                        </div>
                        <div>
                            <div className='valueLabel'>Umówione spotkania</div>
                            <div className='valueLabel'>Umowy rezerwacyjne</div>
                        </div>
                        <img src={lineYDashed} className='lineYDashedPos' alt='Line' />
                    </div>
                    <div className='percentageContainer' >
                        <p className='percentageValue'>{isFinite(funnel.results[0].p7 / funnel.results[0].p5) ? isNaN(funnel.results[0].p7 / funnel.results[0].p5) ? '0' : ((funnel.results[0].p7 / funnel.results[0].p5) * 100).toFixed(2) + '%' : '-'}</p>
                    </div>
                    {/* <div className='box3ShadowContainer'>
                        <div className='box3Shadow'></div>
                        <div className='box3ShadowMask'></div>
                    </div> */}
                </div>

                {/* Box p4p6 */}
                <div className="box4 tile tile1x3long">
                    <div className='rightBoxContainer'>
                        <div>
                            <img src={iconCircleP4Color} className='imgSizeM' alt='Circle' />
                            <img src={iconCircleP6Color} className='imgSizeM' alt='Circle' />
                        </div>
                        <div>
                            <div className='valueLabel'>Kontakt z klientem</div>
                            <div className='valueLabel'>Zrealizowane spotkania</div>
                        </div>
                        <img src={lineYDashed} className='lineYDashedPos' alt='Line' />
                    </div>
                    <div className='percentageContainer' >
                        <p className='percentageValue'>{isFinite(funnel.results[0].p6 / funnel.results[0].p4) ? isNaN(funnel.results[0].p6 / funnel.results[0].p4) ? '0' : ((funnel.results[0].p6 / funnel.results[0].p4) * 100).toFixed(2) + '%' : '-'}</p>
                    </div>
                    {/* <div className='box4ShadowContainer'>
                        <div className='box4Shadow'></div>
                        <div className='box4ShadowMask'></div>
                    </div> */}
                </div>

            </section >
        </div >
    )
}
export default GlobalFunnel;

