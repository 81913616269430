import React from 'react';
import './telephone-contact.scss';
import logoDark from '../../assets/logoDark.svg';
// small icon
import iconTimer from '../../assets/reports/iconTimer.png';
import iconPlus from '../../assets/reports/iconPlus.png';
import iconMinus from '../../assets/reports/iconMinus.png';
// icon Box
import iconBoxPhone from '../../assets/reports/iconBoxPhone.png';
import iconBoxPeople from '../../assets/reports/iconBoxPeople.png';
import iconBoxChecked from '../../assets/reports/iconBoxChecked.png'
import iconBoxSell from '../../assets/reports/iconBoxSell.png';
// hook
import useWindowDimensions from '../../hook/useWindowDimensions.js';

function TelephoneContact() {
    const { height, width } = useWindowDimensions();

    return (
        <div className="telephoneContactContainer">
            {/* SECTION HEADER */}
            <header id="headerTelephoneContact">
                <img src={logoDark} width="162px" alt='Ziemia inwestycje' />
                <h1 className="titleTelephoneContact">Kontakt telefoniczny</h1>
                <div className="switchColony">
                    <div>2 / 8</div>
                    <img src={iconTimer} className='imgSizeM' alt='timer' />
                </div>
            </header>
            <span hidden>width: {width} ~ height: {height}</span>

            {/* SECTION BOXES */}
            <section className="wrapperTel" style={(height == '1080') && (width == '1920') ? { zoom: '135%' } : undefined}>

                {/* Wykonane nowe telefony */}
                <div className="box1tel tile tile2x2">
                    <div className='boxTitle'>
                        <img src={iconBoxPhone} className='imgSizeXl' alt='Phone icon' />
                        <p>Wykonane nowe telefony</p>
                    </div>

                    <div className='boxValue'>
                        <div>
                            <p>116 <span>szt.</span></p>
                            <div>
                                <p>+34</p>
                                <img src={iconPlus} alt='Plus icon' />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="smallBox1tel telBlurEffectOne"></div>

                {/* Nowe umówione spotkania */}
                <div className="box2tel tile tile1x2">
                    <div className='boxTitle'>
                        <img src={iconBoxPeople} className='imgSizeL' alt='People icon' />
                        <p>Nowe umówione spotkania</p>
                    </div>
                    <div className='boxValue'>
                        <div>54 <span>szt.</span></div>
                        <div>
                            <p>+8</p>
                            <img src={iconPlus} alt='Plus icon' />
                        </div>
                    </div>
                </div>
                <div className="smallBox2tel telBlurEffectOne"></div>


                {/* Odbyte spotkania */}
                <div className="box3tel tile tile1x1">
                    <div className='boxTitle'>
                        <p>Odbyte spotkania</p>
                    </div>
                    <div className='boxValue'>
                        <div>32 <span>szt.</span></div>
                    </div>
                </div>
                <div className="smallBox3tel telBlurEffectTwo"></div>

                {/* img box right */}
                <div className="box4tel tile tile1x2img"></div>

                {/* Wykonane telefony */}
                <div className="box5tel tile tile1x1">
                    <div className='boxTitle' >
                        <p>Wykonane telefony</p>
                    </div>
                    <div className='boxValue' >
                        <div>242 <span>szt.</span></div>
                    </div>
                </div>
                <div className="smallBox5tel telBlurEffectTwo"></div>

                {/* Wszystkie odbyte spotkania */}
                <div className="box6tel tile tile1x2">
                    <div className='boxTitle'>
                        <img src={iconBoxPeople} className='imgSizeL inactive' alt='Phone icon' />
                        <p className='inactive'>Wszystkie odbyte spotkania</p>
                    </div>
                    <div className='boxValue'>
                        <div className='inactive'>100 <span>szt.</span></div>
                    </div>
                </div>
                <div className="smallBox6tel telBlurEffectTwo"></div>

                {/* Sprzedaż w zadatkach */}
                <div className="box7tel tile tile2x2">
                    <div className='boxTitle' style={{ display: 'flex', flexDirection: 'row' }}>
                        <img src={iconBoxSell} className='imgSizeXl' alt='Sell icon' style={{ marginRight: '24px' }} />
                        <p>Sprzedaż w zadatkach </p>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', alignSelf: 'flex-start' }}>
                        <div>
                            <p style={{ fontSize: '72px' }}>76,2k <span style={{ fontSize: '36px', color: 'var(--fourth)' }}>zł.</span></p>
                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                <p style={{ fontSize: '20px', color: 'var(--eleventh)', marginRight: '6px', marginBottom: '0px' }}>-2,6</p>
                                <img src={iconMinus} alt='Minus icon' />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="smallBox7tel telBlurEffectThree"></div>

                {/* img box left */}
                <div className="box8tel tile tile1x2img"></div>

                {/* Leady kwalifikowane */}
                <div className="box9tel tile tile1x2">
                    <div className='boxTitle'>
                        <img src={iconBoxChecked} className='imgSizeL' alt='Checked icon' />
                        <div>Leady kwalifikowane</div>
                    </div>
                    <div className='boxValue'>
                        <div>5,6k <span>szt.</span></div>
                        <div>
                            <p>+0,1k</p>
                            <img src={iconPlus} alt='Plus icon' />
                        </div>
                    </div>
                </div>
                <div className="smallBox9tel telBlurEffectOne"></div>

                {/* Wysłane oferty */}
                <div className="box10tel tile tile1x1">
                    <div className='boxTitle'>
                        <p>Wysłane oferty</p>
                    </div>
                    <div className='boxValue'>
                        <div>165 <span>szt.</span></div>
                    </div>
                </div>
                <div className="smallBox10tel telBlurEffectTwo"></div>

            </section >
        </div >
    )
}
export default TelephoneContact;