import React from 'react';
import './styles.css';
import logoDark from '../../assets/logoDark.svg'

function NotFound() {
    return (
        <div className="notFoundContainer">
            <img src={logoDark} width="400" alt='Ziemia inwestycje' style={{ marginTop: "10vh" }} />
            <p style={{ textAlign: "center", marginTop: "10vh" }}>
                <h1 style={{ fontSize: '250px' }}>404</h1>
                <h2 style={{ fontSize: '100px' }}>Not found</h2>
            </p>
        </div>
    )
}

export default NotFound;