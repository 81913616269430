import React from 'react';
import './sale.scss';
import logoDark from '../../assets/logoDark.svg';
// small icon
import iconTimer from '../../assets/reports/iconTimer.png';
import iconPlus from '../../assets/reports/iconPlus.png';
import iconMinus from '../../assets/reports/iconMinus.png';
import lineBox from '../../assets/reports/lineBox.png';
// icon Box
import iconBoxRouter from '../../assets/reports/iconBoxRouter.png';
import iconBoxPeople from '../../assets/reports/iconBoxPeople.png';
import iconInfinity from '../../assets/reports/iconInfinity.png';
// hook
import useWindowDimensions from '../../hook/useWindowDimensions.js';

function Sale() {
    const { height, width } = useWindowDimensions();

    return (
        <div className="saleContainer">

            {/* SECTION HEADER */}
            <header id="headerSale">
                <img src={logoDark} width="162px" alt='Ziemia inwestycje' />
                <h1 className="titleSale">Sprzedaż</h1>
                <div className="switchColonySale">
                    <div>4 / 8</div>
                    <img src={iconTimer} className='imgSizeM' alt='timer' />
                </div>
            </header>
            <span hidden>width: {width} ~ height: {height}</span>

            {/* SECTION BOXES */}
            <section className="wrapperSale" style={(height == '1080') && (width == '1920') ? { zoom: '135%' } : undefined}>

                {/* Wartość sprzedaży w zadatkach */}
                <div className="box1sale tile tile2x2">
                    <div className='boxTitle'>
                        <img src={iconBoxRouter} className='imgSizeXl' alt='Router icon' />
                        <p>Wartość sprzedaży w zadatkach</p>
                    </div>
                    <div className='boxValue'>
                        <div>
                            <p>44 444 444 <span>zł</span></p>
                            <div>
                                <p>+0,2 mln</p>
                                <img src={iconPlus} alt='Plus icon' />
                            </div>
                        </div>
                    </div>
                </div>

                {/* Akty notarialne w szt. */}
                <div className="box2sale tile tile1x2">
                    <div className='boxTitle'>
                        <div>Akty notarialne w szt.</div>
                        <img src={lineBox} style={{ height: '1px' }} alt='Line' />
                        <div>Akty notarialne w zł</div>
                    </div>
                    <div className='boxValue'>
                        <div>444 <span>szt.</span></div>
                        <img src={lineBox} style={{ height: '1px' }} alt='Line' />
                        <div>44 444 444 <span>szt.</span></div>
                    </div>
                </div>

                {/* img box right 1 */}
                <div className="box3sale tile tile1x1img"></div>

                {/* Podpisane umowy rezerwacyjne */}
                <div className="box4sale tile tile1x2">
                    <div className='boxTitle'>
                        <img src={iconInfinity} className='imgSizeL' alt='Infinity icon' />
                        <div>Podpisane umowy rezerwacyjne</div>
                    </div>
                    <div className='boxValue'>
                        <div>444 <span>szt.</span></div>
                        <div>
                            <p>+288</p>
                            <img src={iconPlus} alt='Plus icon' />
                        </div>
                    </div>
                </div>

                {/* img box left 1 */}
                <div className="box5sale tile tile1x1img"></div>

                {/* Akty not. do umówienia */}
                <div className="box6sale tile tile1x2">
                    <div className='boxTitle'>
                        <div>Akty not. <strong>do umówienia</strong> w szt.</div>
                        <img src={lineBox} style={{ height: '1px' }} alt='Line' />
                        <div>Akty not. <strong>do umówienia</strong> w zł.</div>
                    </div>
                    <div className='boxValue'>
                        <div>123 <span>szt.</span></div>
                        <img src={lineBox} style={{ height: '1px' }} alt='Line' />
                        <div>12 345 678 <span>szt.</span></div>
                    </div>
                </div>

                {/* Odbyte spotkania */}
                <div className="box7sale tile tile2x2">
                    <div className='boxTitle'>
                        <img src={iconBoxPeople} className='imgSizeXl' alt='Sell icon' />
                        <div>Odbyte spotkania</div>
                    </div>
                    <div className='boxValue'>
                        <div>
                            <p>317 <span>szt.</span></p>
                            <div>
                                <p>-4</p>
                                <img src={iconMinus} alt='Minus icon' />
                            </div>
                        </div>
                    </div>
                </div>

                {/* Zadatki */}
                <div className="box8sale tile tile1x2">
                    <div className='boxTitle' >
                        <img src={iconInfinity} className='imgSizeL' alt='Infinity icon' />
                        <div>Zadatki</div>
                    </div>
                    <div className='boxValue'>
                        <div>444 <span>szt.</span></div>
                        <div className='smallValue'>
                            <p>+288</p>
                            <img src={iconPlus} alt='Plus icon' />
                        </div>
                    </div>
                </div>

                {/* Akty not. do umówienia */}
                <div className="box9sale tile tile1x2">
                    <div className='boxTitle'>
                        <div>Akty not. <strong>umówione</strong> w szt.</div>
                        <img src={lineBox} style={{ height: '1px' }} alt='Line' />
                        <div>Akty not. <strong>umówione</strong> w zł</div>
                    </div>
                    <div className='boxValue'>
                        <div>987 <span >szt.</span></div>
                        <img src={lineBox} style={{ height: '1px' }} alt='Line' />
                        <div>9 876 543 <span>szt.</span></div>
                    </div>
                </div>

                {/* img box right 2 */}
                <div className="box10sale tile tile1x1img"></div>
            </section >
        </div >
    )
}
export default Sale;