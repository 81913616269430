import React from 'react';
import './styles.css';
import logoDark from '../../assets/logoDark.svg';

function Home() {
    return (
        <div className="homeContainer">
            <img src={logoDark} width="400" alt='Ziemia inwestycje' style={{ marginTop: "10vh" }} />
            <p style={{ textAlign: "center", marginTop: "10vh" }}>
                <h1 style={{ fontSize: '250px' }}>BIZI</h1>
            </p>
        </div>
    )
}

export default Home;