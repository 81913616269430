import React, { useState, useEffect } from 'react';
import './hr-table.scss';
import logoDark from '../../assets/logoDark.svg';
// small icon
import lineBox from '../../assets/reports/lineBox.png';
// hook
import useWindowDimensions from '../../hook/useWindowDimensions.js';
// svg icon
import icCalendar from '../../assets/icCalendar.svg';
import icBirthday from '../../assets/icBirthday.svg';
import icZiemia from '../../assets/icZiemia.svg';
import icAid from '../../assets/icAid.svg';

const today = new Date();
const date = `${(today.getDate() < 10) ? '0' + today.getDate() : today.getDate()}.${(today.getMonth() + 1 < 10) ? '0' + (today.getMonth() + 1) : today.getDate}.${today.getFullYear()}`;

function HrTable() {
    const { height, width } = useWindowDimensions();
    const [time, setTime] = useState(date);

    useEffect(() => {
        const intervalCall = setInterval(() => {
            // window.location.reload();
            const today = new Date();
            const date = `${(today.getDate() < 10) ? '0' + today.getDate() : today.getDate()}.${(today.getMonth() + 1 < 10) ? '0' + (today.getMonth() + 1) : today.getDate}.${today.getFullYear()}`;
            setTime(date);
            console.info('refresh date:', date);
        }, 3600000);
        return () => {
            clearInterval(intervalCall);
        };
    });

    return (
        <div className="hrContainer">

            {/* SECTION HEADER */}
            <header id="headerHr">
                <img src={logoDark} width="162px" alt='Ziemia inwestycje' />
                <h1 className="titleSale">Tablica HR</h1>
                <div className="switchColonySale">
                    {/* <div style={{ color: '#3EFF8B', letterSpacing: '1px' }}>{time}</div> */}
                </div>
            </header>
            <span hidden>width: {width} ~ height: {height}</span>

            {/* SECTION BOXES */}
            <section className="wrapperHr" style={(height == '1080') && (width == '1920') ? { zoom: '120%' } : undefined}>
                <h3 className="titleCalendar">Kalendarz</h3>

                {/* lipiec */}
                <div className="box1hr tile tile2x2hr">
                    <div className='boxTitle'>
                        <img src={icCalendar} width="50" height="50" alt='Kalendarz' />
                        <p>Październik</p>
                    </div>
                    <div>
                        <img src={lineBox} style={{ height: '1px', width: '100%' }} alt='Line' />
                        <div style={{ display: 'flex' }}>
                            <div style={{ fontSize: '20px', color: '#3EFF8B', marginTop: '5px', marginRight: '15px' }}>|06.10|</div>
                            <img src={icZiemia} width="30px" alt='ZI' style={{ marginRight: '10px' }} />
                            <div style={{ fontSize: '17px', marginTop: '4px', }}>Kino jesienią</div>
                        </div>
                        <img src={lineBox} style={{ height: '1px', width: '100%' }} alt='Line' />
                        <div style={{ display: 'flex' }}>
                            <div style={{ fontSize: '20px', color: '#3EFF8B', marginTop: '5px', marginRight: '15px' }}>|08.10|</div>
                            <img src={icBirthday} width="30px" alt='ZI' style={{ marginRight: '10px' }} />
                            <div style={{ fontSize: '17px', marginTop: '4px' }}>Urodziny Krzysztof Parkitny</div>
                        </div>
                        <img src={lineBox} style={{ height: '1px', width: '100%' }} alt='Line' />
                        <div style={{ display: 'flex' }}>
                            <div style={{ fontSize: '20px', color: '#3EFF8B', marginTop: '5px', marginRight: '18px' }}>|09.10|</div>
                            <img src={icBirthday} width="30px" alt='ZI' style={{ marginRight: '10px' }} />
                            <div style={{ fontSize: '17px', marginTop: '4px' }}>Urodziny Daria Ostrowska</div>
                        </div>
                        <img src={lineBox} style={{ height: '1px', width: '100%' }} alt='Line' />
                        <div style={{ display: 'flex' }}>
                            <div style={{ fontSize: '20px', color: '#3EFF8B', marginTop: '5px', marginRight: '20px' }}>|16.10|</div>
                            <img src={icBirthday} width="30px" alt='ZI' style={{ marginRight: '12px' }} />
                            <div style={{ fontSize: '17px', marginTop: '4px' }}>Urodziny Radosław Kropielnicki</div>
                        </div>
                        <img src={lineBox} style={{ height: '1px', width: '100%' }} alt='Line' />
                        <div style={{ display: 'flex' }}>
                            <div style={{ fontSize: '20px', color: '#3EFF8B', marginTop: '5px', marginRight: '22px' }}>|17.10|</div>
                            <img src={icBirthday} width="30px" alt='ZI' style={{ marginRight: '14px' }} />
                            <div style={{ fontSize: '17px', marginTop: '4px' }}>Urodziny Marcin Kaczorek</div>
                        </div>
                        {/* <img src={lineBox} style={{ height: '1px', width: '100%' }} alt='Line' />
                        <div style={{ display: 'flex' }}>
                            <div style={{ fontSize: '20px', color: '#3EFF8B', marginTop: '5px', marginRight: '14px' }}>|22.10|</div>
                            <img src={icBirthday} width="30px" alt='ZI' style={{ marginRight: '10px' }} />
                            <div style={{ fontSize: '17px', marginTop: '4px' }}>Urodziny Dominika Jagielska</div>
                        </div> */}
                        {/* <img src={lineBox} style={{ height: '1px', width: '100%' }} alt='Line' />
                        <div style={{ display: 'flex' }}>
                            <div style={{ fontSize: '20px', color: '#3EFF8B', marginTop: '5px', marginRight: '17px' }}>|22.07|</div>
                            <img src={icBirthday} width="30px" alt='ZI' style={{ marginRight: '10px' }} />
                            <div style={{ fontSize: '17px', marginTop: '4px' }}>Urodziny Paweł Radaj</div>
                        </div> */}
                        {/* <img src={lineBox} style={{ height: '1px', width: '100%' }} alt='Line' />
                        <div style={{ display: 'flex' }}>
                            <div style={{ fontSize: '20px', color: '#3EFF8B', marginTop: '5px', marginRight: '20px' }}>|27.07|</div>
                            <img src={icZiemia} width="30px" alt='ZI' style={{ marginRight: '10px' }} />
                            <div style={{ fontSize: '17px', marginTop: '4px' }}>Spotkanie 'All hands on deck'</div>
                        </div> */}
                    </div>
                </div>

                {/* Sierpień */}
                <div className="box2hr tile tile2x2hr">
                    <div className='boxTitle'>
                        <img src={icCalendar} width="50" height="50" alt='Kalendarz' />
                        <p>Listopad</p>
                    </div>
                    <div style={{ width: '369px' }}>
                        <img src={lineBox} style={{ height: '1px', width: '100%' }} alt='Line' />
                        <div style={{ display: 'flex' }}>
                            <div style={{ fontSize: '20px', color: '#3EFF8B', marginTop: '5px', marginRight: '15px' }}>|02.11|</div>
                            <img src={icBirthday} width="28px" alt='Ziemia inwestycje' style={{ marginRight: '10px' }} />
                            <div style={{ fontSize: '17px', marginTop: '4px', }}>Urodziny Elżbieta Kuszyk-Wcisło</div>
                        </div>

                        <img src={lineBox} style={{ height: '1px', width: '100%' }} alt='Line' />
                        <div style={{ display: 'flex' }}>
                            <div style={{ fontSize: '20px', color: '#3EFF8B', marginTop: '5px', marginRight: '15px' }}>|05.11|</div>
                            <img src={icBirthday} width="28px" alt='Ziemia inwestycje' style={{ marginRight: '10px' }} />
                            <div style={{ fontSize: '17px', marginTop: '4px', }}>Urodziny Martyna Godzisz</div>
                        </div>

                        <img src={lineBox} style={{ height: '1px', width: '100%' }} alt='Line' />
                        <div style={{ display: 'flex' }}>
                            <div style={{ fontSize: '20px', color: '#3EFF8B', marginTop: '5px', marginRight: '15px' }}>|06.11|</div>
                            <img src={icBirthday} width="28px" alt='Ziemia inwestycje' style={{ marginRight: '10px' }} />
                            <div style={{ fontSize: '17px', marginTop: '4px', }}>Urodziny Patrycja Kondratowicz</div>
                        </div>

                        <img src={lineBox} style={{ height: '1px', width: '100%' }} alt='Line' />
                        <div style={{ display: 'flex' }}>
                            <div style={{ fontSize: '20px', color: '#3EFF8B', marginTop: '5px', marginRight: '19px' }}>|09.11|</div>
                            <img src={icZiemia} width="28px" alt='Ziemia inwestycje' style={{ marginRight: '10px' }} />
                            <div style={{ fontSize: '17px', marginTop: '4px', }}>Śniadanie firmowe</div>
                        </div>

                        <img src={lineBox} style={{ height: '1px', width: '100%' }} alt='Line' />
                        <div style={{ display: 'flex' }}>
                            <div style={{ fontSize: '20px', color: '#3EFF8B', marginTop: '5px', marginRight: '17px' }}>|30.11|</div>
                            <img src={icZiemia} width="28px" alt='Ziemia inwestycje' style={{ marginRight: '10px' }} />
                            <div style={{ fontSize: '17px', marginTop: '4px', }}>Szlachetna Paczka</div>
                        </div>

                        {/* <img src={lineBox} style={{ height: '1px', width: '100%' }} alt='Line' />
                        <div style={{ display: 'flex' }}>
                            <div style={{ fontSize: '20px', color: '#3EFF8B', marginTop: '5px', marginRight: '18px' }}>|24.08|</div>
                            <img src={icBirthday} width="30px" alt='ZI' style={{ marginRight: '10px' }} />
                            <div style={{ fontSize: '17px', marginTop: '4px' }}>Urodziny Krystian Pankiewicz</div>
                        </div> */}

                        {/* <img src={lineBox} style={{ height: '1px', width: '100%' }} alt='Line' />
                        <div style={{ display: 'flex' }}>
                            <div style={{ fontSize: '20px', color: '#3EFF8B', marginTop: '5px', marginRight: '18px' }}>|29.08|</div>
                            <img src={icBirthday} width="30px" alt='ZI' style={{ marginRight: '10px' }} />
                            <div style={{ fontSize: '17px', marginTop: '4px' }}>Urodziny Karol Antoniak</div>
                        </div> */}
                    </div>
                </div>

                {/* Wrzesień */}
                <div className="box3hr tile tile2x2hr">
                    <div className='boxTitle'>
                        <img src={icCalendar} width="50" height="50" alt='Kalendarz' />
                        <p>Grudzień</p>
                    </div>
                    <div style={{ width: '369px' }}>
                        <img src={lineBox} style={{ height: '1px', width: '100%' }} alt='Line' />
                        <div style={{ display: 'flex' }}>
                            <div style={{ fontSize: '20px', color: '#3EFF8B', marginTop: '5px', marginRight: '15px' }}>|06.12|</div>
                            <img src={icZiemia} width="28px" alt='Ziemia inwestycje' style={{ marginRight: '10px' }} />
                            <div style={{ fontSize: '17px', marginTop: '4px', }}>Mikołajki</div>
                        </div>
                        <img src={lineBox} style={{ height: '1px', width: '100%' }} alt='Line' />
                        <div style={{ display: 'flex' }}>
                            <div style={{ fontSize: '20px', color: '#3EFF8B', marginTop: '5px', marginRight: '18px' }}>|16.12|</div>
                            <img src={icBirthday} width="30px" alt='ZI' style={{ marginRight: '10px' }} />
                            <div style={{ fontSize: '17px', marginTop: '4px' }}>Urodziny Marta Łacińska</div>
                        </div>
                        <img src={lineBox} style={{ height: '1px', width: '100%' }} alt='Line' />
                        <div style={{ display: 'flex' }}>
                            <div style={{ fontSize: '20px', color: '#3EFF8B', marginTop: '5px', marginRight: '18px' }}>|16.12|</div>
                            <img src={icZiemia} width="30px" alt='ZI' style={{ marginRight: '10px' }} />
                            <div style={{ fontSize: '17px', marginTop: '4px' }}>Wigilia firmowa</div>
                        </div>
                        <img src={lineBox} style={{ height: '1px', width: '100%' }} alt='Line' />
                        <div style={{ display: 'flex' }}>
                            <div style={{ fontSize: '20px', color: '#3EFF8B', marginTop: '5px', marginRight: '14px' }}>|22.12|</div>
                            <img src={icBirthday} width="30px" alt='ZI' style={{ marginRight: '10px' }} />
                            <div style={{ fontSize: '17px', marginTop: '4px' }}>Urodziny Edyta Piątek</div>
                        </div>
                    </div>
                </div>



                <div hidden className="title2Calendar">Aktualnie prowadzone rekrutacje</div>
                <div hidden className="box4hr tile tilebottom tileRecruitment">
                    <div style={{ width: '100%' }}>
                        {/* <div style={{ display: 'flex' }}>
                            <img src={icZiemia} width="28px" alt='Ziemia inwestycje' style={{ marginRight: '10px' }} />
                            <div style={{ fontSize: '17px', marginTop: '4px', }}>Performance Marketing Specialist</div>
                        </div> */}

                        {/* <img src={lineBox} style={{ height: '1px', width: '100%' }} alt='Line' />
                        <div style={{ display: 'flex' }}>
                            <img src={icZiemia} width="28px" alt='Ziemia inwestycje' style={{ marginRight: '10px' }} />
                            <div style={{ fontSize: '17px', marginTop: '4px', }}>Manager działu technicznego</div>
                        </div>
                        <img src={lineBox} style={{ height: '1px', width: '100%' }} alt='Line' /> */}

                        {/* <div style={{ display: 'flex' }}>
                            <img src={icZiemia} width="30px" alt='ZI' style={{ marginRight: '10px' }} />
                            <div style={{ fontSize: '17px', marginTop: '4px' }}>Specjalista ds. sprzedaży terenowej (Gorzów Wielkopolski i okolice)</div>
                        </div>
                        <img src={lineBox} style={{ height: '1px', width: '100%' }} alt='Line' /> */}

                        {/* <div style={{ display: 'flex' }}>
                            <img src={icZiemia} width="30px" alt='ZI' style={{ marginRight: '10px' }} />
                            <div style={{ fontSize: '17px', marginTop: '4px' }}>Koordynator ds. technicznych</div>
                        </div>
                        <img src={lineBox} style={{ height: '1px', width: '100%' }} alt='Line' /> */}

                        {/* <div style={{ display: 'flex' }}>
                            <img src={icZiemia} width="30px" alt='ZI' style={{ marginRight: '10px' }} />
                            <div style={{ fontSize: '17px', marginTop: '4px' }}>Specjalista ds. telefonicznej sprzedaży gruntów</div>
                        </div>
                        <img src={lineBox} style={{ height: '1px', width: '100%' }} alt='Line' /> */}

                        <div style={{ display: 'flex' }}>
                            <img src={icZiemia} width="30px" alt='ZI' style={{ marginRight: '10px' }} />
                            <div style={{ fontSize: '17px', marginTop: '4px' }}>Manager działu sprzedaży i marketingu</div>
                        </div>
                        <img src={lineBox} style={{ height: '1px', width: '100%' }} alt='Line' />

                        <div style={{ color: '#3EFF8B', letterSpacing: '1px', fontSize: '16px' }}>Więcej informacji: <span style={{ color: 'white' }}> hr@ziemiainwestycje.pl</span> </div>
                    </div>
                </div>

            </section >
        </div >
    )
}
export default HrTable;