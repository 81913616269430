import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import './styles.css';

const API = 'https://api.ziemiainwestycje.pl/funnel/funnel-data/';

function Funnel() {
    const [apiValue, setApiValue] = useState(false);
    const [data, setData] = useState([]);
    const errorDataShort = '-';
    const date = new Date().toTimeString().replace(/.*(\d{2}:\d{2}:\d{2}).*/, "$1");
    const p1Value = apiValue ? parseInt(data.p1).toFixed() : errorDataShort;
    const p2Value = apiValue ? parseInt(data.p2).toFixed() : errorDataShort;
    const p3Value = apiValue ? parseInt(data.p3).toFixed() : errorDataShort;
    const p4Value = apiValue ? parseInt(data.p4).toFixed() : errorDataShort;
    const p1p2percent = apiValue ? ((data.p2 / data.p1) * 100).toFixed(2) + '%' : errorDataShort;
    const p2p3percent = apiValue ? ((data.p3 / data.p2) * 100).toFixed(2) + '%' : errorDataShort;
    const p3p4percent = apiValue ? ((data.p4 / data.p3) * 100).toFixed(2) + '%' : errorDataShort;
    const p4p5percent = apiValue ? ((data.p5 / data.p4) * 100).toFixed(2) + '%' : errorDataShort;
    const p5p6percent = apiValue ? ((data.p6 / data.p5) * 100).toFixed(2) + '%' : errorDataShort;
    const p6p7percent = apiValue ? ((data.p7 / data.p6) * 100).toFixed(2) + '%' : errorDataShort;
    const p7p8percent = apiValue ? ((data.p8 / data.p7) * 100).toFixed(2) + '%' : errorDataShort;
    const p4p8percent = apiValue ? ((data.p8 / data.p4) * 100).toFixed(2) + '%' : errorDataShort;
    const p6p8percent = apiValue ? ((data.p8 / data.p6) * 100).toFixed(2) + '%' : errorDataShort;

    const initialData = () => {
        fetch(`${API}`)
            .then(function (response) {
                return response.json();
            })
            .then(function (myJson) {
                setData(myJson.results[0]);
                setApiValue(true);
                console.info('initial data has loaded: ', data, 'at time', date);
            })
    }

    const getData = () => {
        fetch(`${API}`)
            .then(function (response) {
                return response.json();
            })
            .then(function (myJson) {
                setData(myJson.results[0]);
                // console.info('refresh time: ', date);
                console.log('new date: ', data);
            });
    }

    useEffect(() => {
        initialData();
        const intervalCall = setInterval(() => {
            getData();
        }, 3600000);
        return () => {
            clearInterval(intervalCall);
        };
    }, []);

    return (
        <div className="container boxContainer" id="funnelId">
            <div className="col">
                <div className="row">
                    <div className="col-12">
                        <div className="row">
                            <div className="col-2">
                            </div>
                            <div className="col-10" style={{ marginBottom: "5vh", marginTop: "2vh", display: 'flex', justifyContent: "center" }}>
                                <h1>Podróż klienta po organizacji</h1>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-11" style={{ backgroundColor: "rgba(246,244,229, 0.1)" }}>
                        <div className="row">
                            <div className="col-2 p-box left">
                                {p1Value.replace(/\B(?=(\d{3})+(?!\d))/g, " ")}
                            </div>
                            <div className="col-10 boxFunnel p1-box">
                                P1 - ZASIĘG
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-2 p-box left">
                                {p2Value.replace(/\B(?=(\d{3})+(?!\d))/g, " ")}
                            </div>
                            <div className="col-9 boxFunnel p2-box">
                                P2 - WEJŚCIA
                                <div className="percentageBox">{p1p2percent}</div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-2 p-box left">
                                {p3Value.replace(/\B(?=(\d{3})+(?!\d))/g, " ")}
                            </div>
                            <div className="col-8 boxFunnel p3-box">
                                P3 - INTERAKCJA
                                <div className="percentageBox">{p2p3percent}</div>
                            </div>
                        </div>
                    </div>
                    <div className="col-1 right" style={{ display: "flex", justifyContent: "center", color: "white", backgroundColor: "rgba(246,244,229,0.1)" }}>MARKETING</div>
                </div>

                <div className="row" >
                    <div className="col-11" style={{ backgroundColor: "rgba(243,235,228,0.15)" }}>
                        <div className="row">
                            <div className="col-2 p-box left">
                                {p4Value.replace(/\B(?=(\d{3})+(?!\d))/g, " ")}
                            </div>
                            <div className="col-7 boxFunnel p4-box">
                                P4 - KONTAKT Z POTENCJALNYM KLIENTEM
                                <div className="percentageBox">{p3p4percent}</div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-2 p-box left">
                                {apiValue ? data.p5 : '-'}
                            </div>
                            <div className="col-6 boxFunnel p5-box">
                                P5 - UMÓWIONE SPOTKANIA
                                <div className="percentageBox">{p4p5percent}</div>
                            </div>
                        </div>
                    </div>
                    <div className="col-1 right" style={{ display: "flex", justifyContent: "center", color: "white", backgroundColor: "rgba(243,235,228,0.15)" }}>DSZ</div>
                </div>

                <div className="row" >
                    <div className="col-11" style={{ backgroundColor: "rgba(246,244,229,0.2)" }}>
                        <div className="row">
                            <div className="col-2 p-box left">
                                {apiValue ? data.p6 : '-'}
                            </div>
                            <div className="col-5 boxFunnel p6-box">
                                P6 - ZREALIZOWANE SPOTKANIA
                                <div className="percentageBox">{p5p6percent}</div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-2 p-box left">
                                {apiValue ? data.p7 : '-'}
                            </div>
                            <div className="col-4 boxFunnel p7-box">
                                P7 - UMOWY REZERWACYJNE
                                <div className="percentageBox">{p6p7percent}</div>
                            </div>
                        </div>
                    </div>
                    <div className="col-1 right" style={{ display: "flex", justifyContent: "center", color: "white", backgroundColor: "rgba(246,244,229,0.2)" }}>DST</div>
                </div>

                <div className="row" >
                    <div className="col-11" style={{ backgroundColor: "rgba(243,235,228, 0.3)" }}>
                        <div className="row">
                            <div className="col-2 p-box left">
                                {apiValue ? data.p8 : '-'}
                            </div>
                            <div className="col-3 boxFunnel p8-box">
                                P8 - AKTY NOTARIALNE
                                <div className="percentageBox">{p7p8percent}</div>
                            </div>
                        </div>
                    </div>
                    <div className="col-1 right" style={{ display: "flex", justifyContent: "center", color: "white", backgroundColor: "rgba(243,235,228,0.3)" }}>DU</div>
                </div>


                {/* small boxes section */}
                <div className="row" style={{ marginTop: "8vh" }}>

                    <div className="col-5" style={{ marginLeft: "230px" }}>
                        <div className="row" style={{ marginRight: "-50px" }}>
                            <div className="col-2 p-box left">
                            </div>
                            <div className="col-5 boxFunnel p4-box-small">
                                P4 - KONTAKT Z POTENCJALNYM KLIENTEM
                            </div>
                        </div>
                        <div className="row" style={{ marginRight: "-50px" }}>
                            <div className="col-2 p-box" left>
                            </div>
                            <div className="col-5 boxFunnel p8-box-small">
                                P8 - AKTY NOTARIALNE
                                <div className="percentageBox">{p4p8percent}</div>
                            </div>
                        </div>
                    </div>

                    <div className="col-5">
                        <div className="row" style={{ marginRight: "-45px" }}>
                            <div className="col-1 p-box">
                            </div>
                            <div className="col-5 boxFunnel p6-box-small">
                                P6 - ZREALIZOWANE SPOTKANIA
                            </div>
                        </div>
                        <div className="row" style={{ marginRight: "-45px" }}>
                            <div className="col-1 p-box">
                            </div>
                            <div className="col-5 boxFunnel p8-box-small">
                                P8 - AKTY NOTARIALNE
                                <div className="percentageBox">{p6p8percent}</div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div >
    )
}

export default Funnel;